import React from "react";
import { FaInstagram } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";

import Layout from "../components/Layout";
import { LandingContacts, LandingContainer } from "../styled/Layout.styled";
import { Logo } from "../components/Logo";

const Home = ({ location }) => {
  return (
    <Layout pathname={location.pathname}>
      <LandingContainer bg="/img/bg3.jpg">
        <Logo
          width="50vw"
          height="50vh"
          color="rgba(0, 0, 0, 0.9)"
          shadows={[
            "2px 2px 4px rgba(190,190,190,0.5)",
            "-2px -2px 4px rgba(190,190,190,0.5)",
            "2px -2px 4px rgba(255,255,255,0.8)",
            "-2px 2px 4px rgba(255,255,255,0.8)",
          ]}
        />
        <LandingContacts>
          <a href="mailto:mourkovakarolina@gmail.com">
            <MdOutlineMail size={38} />
          </a>
          <a href="https://www.instagram.com/kaya_mourek_art" target="_blank">
            <FaInstagram size={36} />
          </a>
        </LandingContacts>
      </LandingContainer>
    </Layout>
  );
};

export default Home;
